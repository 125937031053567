import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"
import { Carousel } from "../../Carousel"
import {
  NextButton,
  PreviousButton,
  DotButton,
  EmblaComponent,
  DotsContainer,
} from "../../Carousel/style"

export const TestimonialModuleContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background: ${props => props.theme.navy};
  padding: 54px 20px 9px;
  margin-top: 50px;

  > svg {
    position: absolute;
    height: 54px;
    width: 56px;
    top: -20px;
    left: calc(50% - 28px);

    @media ${device.desktop} {
      height: 91px;
      width: 96px;
      top: -30px;
      left: calc(50% - 50px);
      max-width: 631px;
    }
  }

  @media ${device.desktop} {
    padding: 105px 0 40px;
    margin: 125px 0 96px;
    max-width: 1660px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const PrimaryQuote = styled.p`
  font-weight: 300 !important;
  font-size: 30px;
  color: #ffffff !important;
  line-height: 120% !important;
  width: 100%;

  @media ${device.desktop} {
    font-size: 42px !important;
    letter-spacing: -0.66px !important;
    line-height: 48px !important;
  }
`

export const SecondaryQuote = styled.p`
  font-size: 16px;
  color: #ffffff !important;
  line-height: 160% !important;
  margin-top: 8px;

  @media ${device.desktop} {
    font-size: 18px !important;
    margin-top: 20px;
    width: 95%;
  }
`

export const Name = styled.p`
  font-size: 14px;
  color: #ffffff !important;
  text-align: center;
  font-weight: 600;
  position: relative;
  margin-top: 60px;
  margin-bottom: 32px;

  @media ${device.desktop} {
    padding-left: 47px;
    margin-top: 70px;
    margin-bottom: 25px;
  }

  :before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    top: -12px;
    height: 2px;
    background: ${props => props.theme.peach};
    width: 32px;
    @media ${device.desktop} {
      top: -17px;
    }
  }
`

export const HomeCarousel = styled(Carousel)`
  ${NextButton} {
    display: none;
    @media ${device.desktop} {
      display: block;
      background: transparent;
      position: absolute;
      top: calc(50% - 30px);
      right: 75px;
      border: 2px solid #5c6370;

      :hover {
        border-color: #f17071;
        svg path {
          fill: #f17071;
        }
      }
    }
  }
  ${PreviousButton} {
    left: 75px;
  }

  ${DotButton} {
    border: none;
    background: none;
    margin-left: 0;
    width: 15px;
    :hover {
      background: none;
    }
  }
  ${EmblaComponent} {
    overflow: visible !important;
    max-width: 631px;
  }
  ${DotsContainer} {
    margin-top: 23px;
    position: relative;
  }
`
