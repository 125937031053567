import React from "react"
import { ReactComponent as Quotation } from "images/icons/front-quotations.svg"
import { CustomButton1 } from "components/customButtons"

import {
  TestimonialModuleContainer,
  PrimaryQuote,
  SecondaryQuote,
  TextWrapper,
  Name,
  HomeCarousel,
} from "./style"

const TestimonialHome = ({ avTestimonials }) => {
  const { avtButtonText, avtButtonLink, testimonials } = avTestimonials
  return (
    <TestimonialModuleContainer>
      <Quotation />
      <HomeCarousel
        gap={1000}
        loop
        align="center"
        slidesToShow={1}
        autoPlay
        interval={10}
      >
        {testimonials &&
          testimonials.map((testimonial, index) => {
            const { author, description, heading } = testimonial
            return (
              <TextWrapper key={index}>
                <PrimaryQuote>{heading}</PrimaryQuote>
                <SecondaryQuote>{description}</SecondaryQuote>
                <Name>{author}</Name>
                {avtButtonText && (
                  <CustomButton1
                    whiteText
                    link={avtButtonLink}
                    label={avtButtonText}
                  ></CustomButton1>
                )}
              </TextWrapper>
            )
          })}
      </HomeCarousel>
    </TestimonialModuleContainer>
  )
}

export default TestimonialHome
