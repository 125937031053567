import styled from "styled-components"
import { device } from "breakpoints"
import AvenueImg from "components/AvenueImg"
import { MainColumn } from "components/MainColumn"

export const TestimonialModuleContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-width: ${props => (props.campaign ? "1440px" : "1660px")};

  @media ${device.mobile} {
    padding: 20px 0;
  }

  @media ${device.tablet} {
    margin-bottom: 60px;
  }

  @media ${device.desktop} {
    margin-bottom: ${props => (props.customBlock ? "49px" : "80px")};
  }
  @media ${device.big} {
    padding: 40px 0;
  }
`

export const ContentWrapper = styled(MainColumn)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media ${device.desktop} {
    flex-direction: row;
  }
`
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 100px;
  @media ${device.desktop} {
    text-align: left;
    flex-basis: 50%;
    width: 50%;
    align-items: flex-start;
    padding: 100px 0;
    padding-right: 40px;
  }

  @media ${device.large} {
    flex-basis: 58%;
    width: 58%;
    padding: 115px 0;
    padding-right: 0px;
  }

  svg {
    position: absolute;
    height: 34px;
    top: 50px;
    left: calc(50% - 110px);
    transform: translateX(-50%);

    @media (min-width: 375px) {
      height: 54px;
      left: calc(50% - 130px);
    }

    @media ${device.desktop} {
      position: static;
      height: 100px;
      width: 105px;
      top: auto;
      left: auto;
      transform: none;
      margin-bottom: 30px;
    }

    @media ${device.large} {
      width: 129px;
      height: 123px;
    }
  }
`
export const BackgroundSlant = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: calc(100% - 20px);
  background: ${props => props.theme.navy};
  clip-path: polygon(0 16vw, 100% 0, 100% calc(100% - 16vw), 0 100%);

  @media ${device.tablet} {
    clip-path: polygon(0 14vw, 100% 0, 100% calc(100% - 14vw), 0 100%);
  }

  @media (min-width: 1660px) {
    clip-path: polygon(0 29%, 100% 0, 100% 71%, 0 100%);
  }
`

export const ProfileImg = styled(AvenueImg)`
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
  border-radius: 50%;

  @media ${device.desktop} {
    width: 40%;
    order: 2;
    margin: 0;
    margin-left: auto;
    align-self: flex-end;
    height: 507px;
    width: 507px;
  }
`

export const PrimaryQuote = styled.p`
  font-weight: 300 !important;
  font-size: 30px;
  color: #ffffff !important;
  line-height: 120% !important;
  font-style: italic;
  margin-bottom: 20px;
  width: 100%;
  @media ${device.desktop} {
    font-size: 38px !important;
    margin-bottom: 30px;
    letter-spacing: -0.66px !important;
  }

  @media ${device.large} {
    font-size: 42px !important;
  }
`

export const SecondaryQuote = styled.p`
  font-size: 16px;
  color: #ffffff !important;
  line-height: 160% !important;
  margin-bottom: 35px;
  width: 100%;

  @media ${device.desktop} {
    font-size: 18px !important;
    margin-bottom: 30px;
    width: 85%;
  }
`

export const Name = styled.p`
  font-size: 14px;
  color: #ffffff !important;
  text-align: center;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;

  @media ${device.desktop} {
    padding-left: 47px;
    margin-bottom: 35px;
  }

  :before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    top: -12px;
    height: 2px;
    background: ${props => props.theme.peach};
    width: 32px;

    @media ${device.desktop} {
      top: 50%;
      transform: translate3d(0, -50%, 0);
      left: 0px;
    }
  }
`
