import React from "react"
import { ReactComponent as Quotation } from "images/icons/front-quotations.svg"
import { CustomButton1 } from "components/customButtons"
import {
  TestimonialModuleContainer,
  ProfileImg,
  PrimaryQuote,
  SecondaryQuote,
  ContentWrapper,
  TextWrapper,
  Name,
  BackgroundSlant,
} from "./style"

const TestimonialModule = ({ data, customBlock }) => {
  const fluid = data.tImage
    ? {
        ...data.tImage,
        sizes: "(min-width: 1024px) 507px, 150px",
      }
    : {
        sourceUrl: data.sourceurl,
        srcSet: data.srcset,
        sizes: "100vw",
        aspectRatio: 1,
      }
  return (
    <TestimonialModuleContainer
      campaign={data.campaign}
      customBlock={customBlock}
    >
      <BackgroundSlant />
      <ContentWrapper>
        <ProfileImg fluid={fluid} />
        <TextWrapper>
          <Quotation />

          <PrimaryQuote>
            {data.tPrimaryQuote || data.tprimaryquote}
          </PrimaryQuote>
          <SecondaryQuote>
            {data.tSecondaryQuote || data.tsecondaryquote}
          </SecondaryQuote>
          <Name>{data.tAuthor || data.tauthor}</Name>
          {data.tLinkText && (
            <CustomButton1
              whiteText
              link={data.tLink}
              label={data.tLinkText}
            ></CustomButton1>
          )}
        </TextWrapper>
      </ContentWrapper>
    </TestimonialModuleContainer>
  )
}

export default TestimonialModule
